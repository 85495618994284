<template>
    <div class="wd-settled">
		<div class="settled-header">
			
		</div>
        <div class="settled-form">
        <el-form ref="myForm" class="submit_info" :label-position="'left'" :rules="rules" label-width="168px" :model="formData" @submit.native.prevent>
            <p class="tipTxt" v-if="tipShow"><svg-icon icon-class="ic-lb"></svg-icon>
                审核结果将以短信形式通知到联系人手机号，请留意查收
                <span class="closeTip"  @click="closeTip">
					<svg-icon icon-class="settled-close"></svg-icon>
				</span>
            </p>
            <el-form-item label="机构名称:" prop="name">
                <el-input v-model="formData.name" size="small" :maxlength="40" placeholder="请输入机构名称"></el-input>
            </el-form-item>
            <el-form-item label="联系人:" prop="userName">
                <el-input v-model="formData.userName" size="small" :maxlength="30" placeholder="请输入联系人姓名"></el-input>
            </el-form-item>
            <el-form-item label="联系电话:" prop="phone" class="hasTip" style="margin-bottom:8px">
                <el-input v-model.trim="formData.phone" size="small" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <div class="authCode formItemTip">
                <el-input v-model="authCode" size="small" placeholder="请输入验证码" style="width:calc(100% - 106px)"></el-input>
                <el-button v-if="Countdown" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCode">{{Countdown+'s'}}</el-button>
                <el-button v-else-if="isFirstGetAuthcode" class="getcodeBtn" type="primary" size="small" @click="getAuthCode">获取验证码</el-button>
                <el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCode">重新获取</el-button>
            </div>
            <el-form-item label="邮箱:" prop="email" class="hasTip" style="margin-bottom:8px">
                <el-input v-model.trim="formData.email" size="small" placeholder="请输入邮箱"></el-input>
            </el-form-item>
            <div class="authCode formItemTip">
                <el-input v-model="emailAuthCode" size="small" placeholder="请输入验证码" style="width:calc(100% - 106px)"></el-input>
                <el-button v-if="CountdownEmail" class="getcodeBtn getcodeBtnGray" type="primary" size="small" @click="getAuthCodeEamil">{{CountdownEmail+'s'}}</el-button>
                <el-button v-else-if="isFirstGetAuthcodeEmail" class="getcodeBtn" type="primary" size="small" @click="getAuthCodeEamil">获取验证码</el-button>
                <el-button v-else class="getcodeBtn" type="primary" size="small" @click="getAuthCodeEamil">重新获取</el-button>
            </div>
            <el-form-item label="地址:" class="needRequred" style="margin-bottom:10px">
                <el-row type="flex" justify="space-between">
                    <el-col class="selectItem">
                        <el-form-item prop="province" style="margin-bottom:0">
                            <el-select v-model="formData.province" size="small" placeholder="请选择省" @change="handleProvinceChange">
                                <el-option
                                    v-for="item in provinceList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="selectItem">
                        <el-form-item prop="city" style="margin-bottom:0">
                            <el-select v-model="formData.city" size="small" placeholder="请选择市" @change="handleCityChange">
                                <el-option
                                    v-for="item in cityList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col class="selectItem areaSelect">
                        <el-form-item prop="district" style="margin-bottom:0">
                            <el-select v-model="formData.district" size="small" placeholder="请选择区">
                                <el-option
                                    v-for="item in districtList"
                                    :key="item.code"
                                    :label="item.name"
                                    :value="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form-item>
            <div class="formItemTip">
                <el-input  v-model="formData.addressDetail" size="small" :maxlength="50" placeholder="请输入详细地址"></el-input>
            </div>
            <el-form-item label="统一社会信用代码:" prop="organCode" key="organCode">
                <el-input v-model="formData.organCode" size="small" placeholder="请输入社会统一信用代码"></el-input>
            </el-form-item>
            <el-form-item label="营业执照副本:" prop="businessLicenseImage">
                <el-upload
                    v-loading="licenseImageUploadLoading"
                    class="avatar-uploader"
                    :class="{border:formData.businessLicenseImage}"
                    :action="action"
                    :headers="headers"
                    accept="image/jpg, image/jpeg, image/png"
                    :show-file-list="false"
                    :on-success="licenseImageSuccess"
                    :before-upload="beforeLicenseImageUpload"
                    :on-error="() => {licenseImageUploadLoading = false}">
                    <img v-if="formData.businessLicenseImage" :src="formData.businessLicenseImage" class="avatar">
                    <div v-else>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <p class="uploadTip">点击上传</p>
                    </div>
                    <div slot="tip" class="el-upload__tip"  style="margin-top: -7px">营业执照扫描图片后上传，支持jpg、png格式，图片大小不超过5M。</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="知识产权信息：" prop="rightInfoDto">
                <div class="teacherInfo">
                    <div class="add" @click="handleAdd">
                        <i class="el-icon-circle-plus-outline"></i>
                        <span>添加</span>
                    </div>
                    <div class="tearcherList">
                        <div class="teacher" v-for="(item, index) in formData.rightInfoDto " :key="index">
                            <div class="item">
                                <div class="left">{{ item.name }}</div>
                                <div class="right">
                                    <span class="edit" @click="handleEditTeacher(item)">编辑</span>
                                    <span class="close" @click="handleRemoveTeacher(item)"><i class="el-icon-close"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-form-item>
            <el-form-item label="承诺函：" prop="letterList" class="letterBox">
                <el-upload
                    v-loading="LetterImageUploadLoading"
                    class="avatar-uploader"
                    :class="{ border: formData.businessLicenseImage }"
                    :action="action"
                    :headers="headers"
                    :limit="1"
                    accept="image/jpg, image/jpeg, image/png"
                    :show-file-list="false"
                    :on-success="letterImageSuccess"
                    :before-upload="beforeLetterImageUpload"
                    :on-error="
                        () => {
                            LetterImageUploadLoading = false
                        }
                    "
                >
                    <div v-if="formData.letterList.length>0" >
                        <img :src="formData.letterList[0].url" class="avatar" />
                    </div>
                    <div v-else>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <p class="uploadTip">点击上传</p>
                    </div>
                    <div slot="tip" class="el-upload__tip" style="margin-top: -7px">承诺函扫描图片后上传，支持jpg、png格式，图片大小不超过5M。<el-button type="text" @click="download">下载承诺函模板</el-button></div>
                </el-upload>
                <div class="demoLetter">
                    <div class="img-box">
                        <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" alt="">
                        <div class="viewLarge" @click="viewDemo">
                            查看大图
                        </div>
                    </div>
                </div>
            </el-form-item>
        </el-form>
        <div class="footer-settled">
            <el-button @click="cancel" class="cancelBtn" size="small">取 消</el-button>
            <el-button type="primary" size="small" class="submitBtn" @click="submit">提交申请</el-button>
            <div class="agree">
                <el-checkbox v-model="hasreaded">我同意并遵守</el-checkbox>
                <span class="protocol" @click="dialogShow = true">《机构入驻合作协议》</span>
            </div>
        </div>
        </div>
        <InstitutionInProtocol :dialogShow="dialogShow" @close="handleCloseProtocol"/>
        <el-dialog
			custom-class="addTeacher"
			:title="isEdit ? '编辑知识产权信息' : '添加知识产权信息'"
			:visible.sync="showTeacher"
			width="640px"
			top="10vh"
			:close-on-click-modal="false"
			:modal-append-to-body="false"
			:destroy-on-close="true"
			@closed="handleCloseTeacher"
		>
			<el-form ref="teacherForm" :label-position="'left'" :rules="teacherRules" label-width="120px" :model="teacherData">
				<el-form-item label="知识产权名称：" prop="name">
					<el-input v-model="teacherData.name" size="small" :maxlength="50" placeholder="请输入知识产权名称"></el-input>
				</el-form-item>
				<el-form-item label="知识产权扫描件：" prop="urlList">
					<el-upload
						v-loading="certificateLoading"
						class="avatar-uploader"
						:action="action"
						:headers="headers"
						accept="image/jpg, image/jpeg, image/png"
						list-type="picture-card"
						:file-list="fileList"
						:limit="5"
                        :on-exceed="handleExceed2"
						:on-remove="handleRemoveCertificate"
						:on-success="certificateSuccess"
						:before-upload="beforeCertificateUpload"
						:on-error="
							() => {
								certificateLoading = false
							}
						"
					>
						<div>
							<i class="el-icon-plus avatar-uploader-icon"></i>
							<p class="uploadTip">点击上传</p>
						</div>
						<div slot="tip" class="el-upload__tip">支持jpg、png格式，图片大小不超过5M；最多上传5张。</div>
					</el-upload>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="showTeacher = false" class="cancelBtn" size="small">取消</el-button>
				<el-button type="primary" size="small" class="submitBtn" @click="confirmAdd">确定</el-button>
			</span>
		</el-dialog>
        <el-dialog custom-class="preview" width="900px" center title="承诺函示例" :close-on-click-modal="false" :visible.sync="previewImg">
			<div class="imgBox">
                <img src="https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-h5-renshe/home/knowDemo.png" class="previewImg" alt />
            </div>
		</el-dialog>
    </div>
</template>
<script>
import InstitutionInProtocol from './institutionInProtocol'
import config from '@/config/url'
import { organCodeValidator,isEmail} from '@/utils/validator'
import { exportMethod } from "@/utils/downDoc";
import URL from "@/config/url";
export default {
    name:'institutionsInDialog',
    components:{
        InstitutionInProtocol
    },
    computed:{
        cityList(){
            if(this.formData.province){
                let findItem = this.provinceList.find(item => item.code === this.formData.province)
                return findItem && findItem.children
            }else{
                return []
            }
        },
        districtList(){
            if(this.formData.city){
                let findItem = this.cityList.find(item => item.code === this.formData.city)
                return findItem && findItem.children
            }else{
                return []
            }
        }
    },
    data(){
        const accountValidator = (rule, value, callback) => {
            const isPhone = /^1(?:3[0-9]|4[5-9]|5[0-9]|6[12456]|7[0-8]|8[0-9]|9[0-9])[0-9]{8}$/
            if ( !isPhone.test(value) ) {
                callback(new Error('请输入正确的手机号'))
            } else {
                callback()
            }
        }
        const checkEmail = (rule, value, callback) => {
            if (!isEmail.test(value) ) {
                callback(new Error('请输入正确的邮箱账号'))
            } else {
                callback()
            }
        }
        return{
            tipShow:true,
            LetterImageUploadLoading:false,
            showTeacher:false,
            hasreaded:false,
            certificateLoading:false,
            licenseImageUploadLoading:false,
            action:config.API + '/resource/v1/resource/uploadPicture',
            headers:{
                token:localStorage.getItem('token')
            },
            previewImg:false,
            authCode:'',
            emailAuthCode:'',
            teacherData: {
				name: '',
				urlList: [],
            },
            fileList:[],
            provinceList:[],
            province:'',
            city:'',
            district:'',
            formData:{
                name:'',
                address:'',
                phone:'',
                province:'',
                city:'',
                district:'',
                businessLicenseImage:'',
                letterList:[],
                rightInfoDto:[]
            },
            letterImageUploadLoading:false,
            isEdit:false,
            copyFormData:{},
            rules: {
                rightInfoDto:{required:true,message:'请添加知识产权信息',trigger:'blur' },
                name:{required:true,message:'请输入机构名称',trigger:'blur' },
                userName:{required:true,message:'请输入联系人姓名',trigger:'blur' },
                province:{required:true,message:'请选择省',trigger:'change' },
                city:{required:true,message:'请选择市',trigger:'change' },
                district:{required:true,message:'请选择区',trigger:'change' },
                organCode:[
                    {required:true,message:'请输入社会统一信用代码',trigger:'blur' },
                    {validator:organCodeValidator,trigger:'blur'}
                ],
                letterList: { required: true, message: '请上传承诺函', trigger: 'blur' },
                phone: [
                    {required:true,message:'请输入手机号',trigger:'blur'},
                    {validator: accountValidator,trigger: "blur"}
                ],
                email: [
                    {required:true,message:'请输入邮箱',trigger:'blur'},
                    {validator: checkEmail,trigger: "blur"}
                ],
                businessLicenseImage:{required:true,message:'请上传营业执照副本',trigger:'blur' },
            },
            teacherRules: {
                name: { required: true, message: '请输入知识产权名称', trigger: 'blur' },
                urlList: { required: true, message: '请上传知识产品扫描件', trigger: 'blur' },
            },
            isFirstGetAuthcode:true,
            isFirstGetAuthcodeEmail:true,
            Countdown:0,
            CountdownEmail:0,
            dialogShow:false
        }
    },
    mounted(){
        this.copyFormData = Object.assign({},this.formData)
        this.getRegionList()
    },
    methods:{
        closeTip(){
			this.tipShow = false;
		},
        handleExceed2() {
			this.$message.error('最多上传5张图片')
		},
        download(){
			let myObj = {
				method: "get",
				url: `${URL.API}/saas/v1/branchApplyEnclosure/download`,
			};
			exportMethod(myObj,true);
		},
        viewDemo(){
			this.previewImg = true;
		},
        beforeLetterImageUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.letterImageUploadLoading = true
		},
        async confirmAdd() {
			try {
				await this.$refs.teacherForm.validate()
			} catch (error) {
				return
			}
			let index = this.formData.rightInfoDto.findIndex((item) => {
				return item.uniq === this.teacherData.uniq
			})

			if (index !== -1) {
				this.formData.rightInfoDto .splice(index, 1, { ...this.teacherData })
			} else {
				this.teacherData.uniq = this.teacherData.name + this.teacherData.url
				this.formData.rightInfoDto.push({ ...this.teacherData })
				this.$refs.myForm.clearValidate('rightInfoDto')
			}
			this.showTeacher = false
        },
        handleCloseTeacher() {
			this.teacherData = {
                name: '',
                urlList:[],
                uniq:'',
			}
			this.fileList = []
			this.showTeacher = false
			this.$nextTick(() => {
				this.$refs.teacherForm.clearValidate()
			})
		},
        handleAdd() {
			if (this.formData.rightInfoDto  && this.formData.rightInfoDto.length === 30) {
				this.$message.error('最多添加30个知识产权信息！')
				return
			}
			this.isEdit = false
			this.showTeacher = true
        },
        handleEditTeacher(item) {
			this.isEdit = true
			this.teacherData = { ...item }
			this.fileList = item.urlList.map((i) => {
				return {
					url: i.url,
				}
			})
			this.$nextTick(() => {
				this.showTeacher = true
			})
		},
		handleRemoveTeacher(item) {
			let index = this.formData.rightInfoDto .findIndex((i) => {
				return i.uniq === item.uniq
			})
			this.formData.rightInfoDto .splice(index, 1)
        },
        letterImageSuccess(res) {
			this.$set(this.formData.letterList,0,{
				url:res.data,
				name:''
			})
			this.letterImageUploadLoading = false
			this.$refs.myForm.clearValidate('letterList')
		},
        beforeCertificateUpload(file) {
			if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
				this.$message.error('请上传jpg、png格式的图片')
				return false
			}
			if (file.size / 1024 / 1024 > 5) {
				this.$message.error('图片大小不能超过5M')
				return false
			}
			this.certificateLoading = true
		},
		certificateSuccess(res) {
			this.teacherData.urlList.push({
                url:res.data
            })
			this.certificateLoading = false
			this.$refs.teacherForm.clearValidate('urlList')
		},
		handleRemoveCertificate(file) {
			// console.log(file, fileList, '-----------------')
			let url = ''
			if (!file.response) {
				url = file.url
			} else {
				url = file.response.data
			}
			let index = this.teacherData.urlList.findIndex((item) => item.url === url)

			this.teacherData.urlList.splice(index, 1)
		},
        getRegionList(){
            this.$api.usercenter.getTree({type:1}).then(res => {
                if(res.success && res.data){
                    let filterArr = res.data.filter(item => {
                        return item.code.length === 2 && item.children
                    })
                    let uniqueArr = Array.from(new Set(filterArr))
                    this.provinceList = uniqueArr
                }
            })
        },
        getAuthCode(){
            this.$refs.myForm.validateField('phone',(error) => {
                if(!error){
                    const data = { account: this.formData.phone,type:2 }
                    if (this.Countdown > 0) return
                    this.Countdown = 60
                    this.setCountdown()
                    this.$api.usercenter.getAuthCodePublic({ data })
                    this.isFirstGetAuthcode = false
                }
            })
        },
        setCountdown() {
            this.CountdownInterval = setInterval(() => {
                this.Countdown--;
                if (this.Countdown <= 0) {
                    clearInterval(this.CountdownInterval);
                }
            }, 1000);
        },
        setCountdownEmail() {
            this.CountdownIntervalEmail = setInterval(() => {
                this.CountdownEmail--;
                if (this.CountdownEmail <= 0) {
                    clearInterval(this.CountdownIntervalEmail);
                }
            }, 1000);
        },
        getAuthCodeEamil(){
            this.$refs.myForm.validateField('email',(error) => {
                if(!error){
                    const data = { account: this.formData.email,type:2 }
                    if (this.CountdownEmail > 0) return
                    this.CountdownEmail = 60
                    this.setCountdownEmail()
                    this.$api.usercenter.getAuthCodePublic({ data })
                    this.isFirstGetAuthcodeEmail = false
                }
            })
        },
        beforeLicenseImageUpload(file){
            if(!['image/jpeg','image/jpg','image/png'].includes(file.type)) {
                this.$message.error('请上传jpg、png格式的图片')
                return false
            }
            if(file.size/1024/1024 > 5) {
                this.$message.error('图片大小不能超过5M')
                return false
            }
            this.licenseImageUploadLoading = true
        },
        licenseImageSuccess(res){
            this.formData.businessLicenseImage = res.data
            this.licenseImageUploadLoading = false
            this.$refs.myForm.clearValidate('businessLicenseImage')
        },
        handleProvinceChange(){
            this.formData.city = ''
            this.formData.district = ''
            this.$nextTick(() => {
                this.$refs.myForm.clearValidate(['city','district'])
            })
        },
        handleCityChange(){
            this.formData.district = ''
            this.$nextTick(() => {
                this.$refs.myForm.clearValidate('district')
            })
        },
        cancel(){
            this.$router.go(-1);
        },
        async submit(){
            const that = this;
            try {
                await this.$refs.myForm.validate()
            } catch (error) {
                return
            }
            if(!this.hasreaded){
                this.$message.warning('请勾选机构入驻合作协议')
                return
            }
            let data = {...this.formData}

            data.regionCode = data.district
            data.authCode = this.authCode
            data.emailAuthCode = this.emailAuthCode
            this.$api.rensheUsercenter.saveResourceCooperation(data).then((res) => {
                if(res.success){
                    this.$emit('closeDialog')
                    this.$alert('审核结果将以短信通知给您，请注意查收', '已提交', {
                        confirmButtonText: '确定',
                        center:true,
                        customClass:'messageBox',
                        confirmButtonClass:'dialog_confirmBtn',
                        callback: () => {
                            that.$router.go(-1);
                        }
                    });
                }
            })
        },
        handleCloseProtocol(){
            this.dialogShow = false
        }
    }
}
</script>
<style lang="stylus" scoped>
@import '../asset/css/institutionsInDialog.styl'
.teacherInfo .tearcherList .teacher .item .left
    width:440px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
.teacherInfo .tearcherList .teacher .item .right
    margin-left:10px;
    .edit
        word-break: keep-all;
.previewImg {
    max-height:460px;
}
.preview
    .imgBox
        text-align:center;
.letterBox
    /deep/.el-form-item__error
        position:absolute;
    /deep/.el-form-item__content
        display flex
        position:relative;
        .el-button
            padding:0;
            text-align:left;
            margin-top:3px;
            span
                line-height:24px;
        .demoLetter
            position:absolute;
            left:135px;
            display flex;
            flex-direction:column;
            margin-left:10px;
            align-items: center;
            .img-box
                position:relative;
                &:hover
                    cursor pointer
                    .viewLarge
                        display:block;
            p
                line-height:24px;
            img
                width:122px;
                height:122px;
                border:1px dashed #ccc;
                border-radius:4px;
                box-sizing:border-box;
                vertical-align: middle;
            .viewLarge
                position:absolute;
                left:0;
                right:0;
                top:0;
                width:122px;
                height:122px;
                background:rgba(0,0,0,.7);
                color:#fff;
                border-radius:4px;
                line-height:124px;
                text-align:center;
                display:none;
.wd-settled
	background: #F8FAFC;
	padding-bottom: 24px
	box-sizing: border-box
	.settled-form>.el-form
		width: 712px
		margin: 0 auto
.settled-header
	height:240px;
	background:url('https://wd-appdownload.oss-cn-beijing.aliyuncs.com/wdn-saas-pc-renshe/settled-edu.png') no-repeat center center ;
	background-size:cover;
.settled-form
	width:1200px;
	margin:-60px auto 0;
	background:#fff;
	border-radius:8px;
	padding-top:28px;
	padding-bottom:32px;
.submit_info /deep/.el-form-item__label{
	padding-right:0;	
}
.tipTxt{
     color:#804515;
    font-size:14px;
    height:32px;
    line-height:32px;
	padding:0 12px;
    background:#FFF1E5;
    border:1px solid #FF7801;
    border-radius:4px;
    width:712px;
    margin:0 auto 24px 0;
    display: flex;
    align-items: center;
    box-sizing: border-box;
	position:relative;
    .icon{
        font-size: 14px;
        margin-right:8px;
    }
}
.tipTxt  .closeTip{
	position:absolute;
	top:50%;
	transform:translateY(-50%);
	content:'';
	right:12px;
    line-height:10px;
    .icon{
        font-size:10px;
        margin-right:0;
    }
    &:hover{
		cursor:pointer
	}
}
.submit_info .el-form-item
	margin-bottom:16px;
/deep/.el-form-item__label
		line-height:32px;
.footer-settled
    margin-top:48px;
    text-align:center;
    .el-button
        width:120px;
.selectItem
	.el-select
		width:100%;

</style>